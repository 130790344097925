<template>
    <section class="blockElement space pt-4 economicCalender space-footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h1 class="tag f-32 bold mt-3 mb-4">{{$t('calendar.text1')}}</h1>
                </div>
                <div class="col-12">
                    <div class="calendarFilter position-relative d-flex align-items-center justify-content-between flex-wrap mb-3">
                        <div class="d-flex align-items-center">
                            <div class="addFilter me-2">
                                <a @click="()=> toggleFilter('country')" class="border-btn d-flex align-items-center" href="javascript:void(0)">
                                    <vue-feather class="me-md-2" type="filter" size="16"></vue-feather>
                                    <span class="name">{{$t('calendar.text2')}}</span>
                                </a>
                                <div class="dropdown_menu_animated" :class="filterOpen.country ? 'show' : ''">
                                    <div class="filterHeader border-bottom p-3 d-flex align-items-center justify-content-between drop-1199">
                                        <div class="position-relative">
                                            <a @click.prevent="dropdownMenu2=!dropdownMenu2" href="javascript:void(0)" class="pt-0 border-btn d-inline-flex align-items-center justify-content-between d-xl-none ms-2">
                                                {{$t('calendar.text3')}} <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i>
                                            </a>
                                            <ul class="onMbile pad-0 selectTab px-2 d-md-flex align-items-center mb-0" :class="dropdownMenu2 ? 'show' : ''">
                                                <li v-for="option,countryFilterKey in countryFilterOptions" :key="countryFilterKey">
                                                    <a href="javascript:void(0)" :class="{'active': currentCountryFilter==option,}" @click="()=> selectCountriesFromTab(option)">{{option}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <a @click.prevent="filterOpen.country=false" href="javascript:void(0)" class="clearAll d-inline-flex flex-shrink-0"> <vue-feather type="x" size="20"></vue-feather> </a>
                                    </div>
                                    <div class="filter-body p-3 border-bottom">
                                        <ul class="d-flex align-items-center flex-wrap country scrollbarFilter flex-wrap mb-0">
                                            <li v-for="country,countryKey in allCountries" :key="countryKey" class="active">
                                                <label class="cutomChechBox text-start">
                                                    {{country}}<input type="checkbox" @click="()=> toggleSelection(country)" :checked="inSelectedCountries(country)" /> <span class="checkmark"></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="filter-footer p-3 d-flex align-items-center justify-content-between flex-wrap">
                                        <!-- <label class="cutomChechBox">Save setting in my filter <input type="checkbox" /> <span class="checkmark"></span> </label> -->
                                        <div class="d-flex align-items-center justify-content-end w-100">
                                            <a href="javascript:void(0)" @click="()=> clearCountries()" class="bold button secondary zulu_btn rounded border-button me-md-3 me-1">{{$t('calendar.text4')}}</a> 
                                            <a @click="()=> getCalendarList()" class="bold button fillBtn zulu_btn">{{$t('calendar.text5')}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="addFilter me-2">
                                <a @click="()=> toggleFilter('impact')" class="border-btn d-flex align-items-center" href="javascript:void(0)">
                                    <span class="d-flex align-items-center impactBtn" :class="{oneStar: filters.impact==1, twoStar: filters.impact==2, threeStar: filters.impact==3,}">
                                        <vue-feather v-if="filters.impact < 1" type="star" size="18px" class="me-md-2" /> 
                                        <vue-feather v-if="filters.impact >=1" class="filed active" type="star" size="18px"></vue-feather>
                                        <vue-feather v-if="filters.impact >=2" class="filed active" type="star" size="18px"></vue-feather> 
                                        <vue-feather v-if="filters.impact >=3" class="filed active" type="star" size="18px"></vue-feather>
                                    </span>
                                    <span class="name">{{$t('calendar.text6')}}</span>
                                </a>
                                <div class="dropdown_menu_animated impact" :class="filterOpen.impact==true ? 'show' : ''">
                                    <div class="filter-importance p-3 border-bottom">
                                        <div class="">
                                            <label class="cutomChechBox">{{$t('calendar.text3')}} <input type="radio" checked="checked" name="radio" value="0" v-model="filters.impact" /> <span class="checkmark"></span> </label>
                                            <label class="cutomChechBox"> <vue-feather type="star" size="20px"></vue-feather> <input type="radio" name="radio" value="1" v-model="filters.impact" /> <span class="checkmark"></span> </label>
                                            <label class="cutomChechBox d-flex align-items-center">
                                                <vue-feather class="me-1" type="star" size="20px"></vue-feather> <vue-feather type="star" size="20px"></vue-feather> <input type="radio" name="radio" value="2" v-model="filters.impact" />
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="cutomChechBox d-flex align-items-center">
                                                <vue-feather class="me-1" type="star" size="20px"></vue-feather> <vue-feather class="me-1" type="star" size="20px"></vue-feather> <vue-feather type="star" size="20px"></vue-feather>
                                                <input type="radio" name="radio" value="3" v-model="filters.impact" /> <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="addFilter">
                                <a @click="()=> toggleFilter('timezone')" class="border-btn d-flex align-items-center" href="javascript:void(0)">
                                    <vue-feather class="me-3 me-md-0" size="15px" type="clock"></vue-feather> <span class="mx-2 name">{{getTimezoneSelected(selectedTimeZone)}}</span> <vue-feather size="18px" type="chevron-down"></vue-feather>
                                </a>
                                <div class="dropdown_menu_animated max-360" :class="filterOpen.timezone ? 'show' : ''">
                                    <div class="filter-gmt">
                                        <ul class="timeZone scrollbarFilter mb-0">
                                            <li v-for="tz in static_vars.timezonesCalendar" :key="tz.value"><a href="javascript:void(0)" @click.prevent="selectedTimeZone=tz.value">{{tz.label}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center flex-wrap mob-100 position-relative drop-1199">
                            <div class="viewCustomDate d-flex align-items-center" v-if="customCalendarDate[0] && customCalendarDate[1]">
                                <div class="fromTo d-flex align-items-center border-btn py-0">
                                    <p class="mb-0 medium">{{$t('calendar.text7')}}</p>
                                    <p class="mb-0 ms-1 f-14">{{formatCustomDate(customCalendarDate[0])}}</p>
                                    <p class="mb-0 ms-2 medium">{{$t('calendar.text8')}}</p>
                                    <p class="mb-0 ms-1 f-14">{{formatCustomDate(customCalendarDate[1])}}</p>
                                </div>
                                <a href="javascript:void(0)" class="closeBtn" v-if="customCalendarDate[0] && customCalendarDate[1]" @click=" customCalendarDate=[]; dateChangeActive='recent'; "> <vue-feather size="16" type="x"></vue-feather> </a>
                            </div>
                            <div class="position-relative disMob mob-100">
                                <a @click.prevent="dropdownMenu=!dropdownMenu" href="javascript:void(0)" class="pt-0 border-btn d-flex align-items-center justify-content-between ms-2 filter-dropdown">
                                    {{filterJson[dateChangeActive]}}<i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i>
                                </a>
                                <ul class="dropdown_menu_animated mh-auto" :class="dropdownMenu ? 'show' : ''">
                                    <li><a class="border-end-0 py-2" :class="{active: dateChangeActive=='recent'}" @click="()=> changeDate('recent')">{{$t('calendar.text9')}}</a></li>
                                    <li><a class="border-end-0 py-2" :class="{active: dateChangeActive=='tomorrow'}" @click="()=> changeDate('tomorrow')">{{$t('calendar.text10')}}</a></li>
                                    <li><a class="border-end-0 py-2" :class="{active: dateChangeActive=='week'}" @click="()=> changeDate('week')">{{$t('calendar.text11')}}</a></li>
                                    <li><a class="border-end-0 py-2" :class="{active: dateChangeActive=='nextWeek'}" @click="()=> changeDate('nextWeek')">{{$t('calendar.text12')}}</a></li>
                                    <li><a class="border-end-0 py-2" :class="{active: dateChangeActive=='nextMonth'}" @click="()=> changeDate('nextMonth')">{{$t('calendar.text13')}}</a></li>
                                    <li><a class="border-end-0 py-2" :class="{active: dateChangeActive=='yesterday'}" @click="()=> changeDate('yesterday')">{{$t('calendar.text14')}}</a></li>
                                    <li><a class="border-end-0 py-2" :class="{active: dateChangeActive=='prevWeek'}" @click="()=> changeDate('prevWeek')">{{$t('calendar.text15')}}</a></li>
                                    <li><a class="border-end-0 py-2" :class="{active: dateChangeActive=='prevMonth'}" @click="()=> changeDate('prevMonth')">{{$t('calendar.text16')}}</a></li>
                                    <li><a class="border-end-0 py-2 custom-date" :class="{active: dateChangeActive=='custom'}" @click=" ()=>{dateChangeActive='custom'; toggleCalendar();}">{{$t('calendar.text17')}}</a></li>
                                </ul>
                            </div>
                            <VueDatePicker
                                :range="true"
                                v-model="customCalendarDate"
                                :multiCalendars="true"
                                inline
                                autoApply
                                :multiCalendarsSolo="true"
                                :enableTimePicker="false"
                                ref="datepicker"
                                v-if="showCalendar"
                                @update:modelValue="handleDate"
                            />
                        </div>
                    </div>
                    <div class="table-responsive bg-white echoCalendar border">
                        <table class="table currencyTable table-sm m-0 border-0 fixed-layout">
                            <thead>
                                <tr>
                                    <th class="neutralGrey" width="100">{{$t('calendar.text18')}}</th>
                                    <th class="neutralGrey" width="200">&nbsp;</th>
                                    <th class="neutralGrey" width="300">&nbsp;</th>
                                    <th class="neutralGrey">{{$t('calendar.text19')}}</th>
                                    <th class="neutralGrey">{{$t('calendar.text20')}}</th>
                                    <th class="neutralGrey">{{$t('calendar.text21')}}</th>
                                    <th class="neutralGrey">{{$t('calendar.text22')}}</th>
                                    <th class="neutralGrey" align="right">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, iOuter) in dateAdjustedEvents" :key="iOuter">
                                    <td colspan="8" class="border-0 p-0">
                                        <table class="table innerTable mb-1">
                                            <tr>
                                                <table class="table innerTable subTable mb-0" :class="hideDateWise.includes(row.date) ? 'hideRow' : '' " >
                                                    <tbody>
                                                        <tr class="pointer" @click="hideDateRow(row)">
                                                            <td class="dateBG" colspan="8">
                                                                <span class="d-flex align-items-center justify-content-between min-70">
                                                                    <span class="d-flex align-items-center">
                                                                        <h6 class="f-15 mb-0">{{formatDate(row.date, "Do MMMM")}}</h6>
                                                                        <span class="totalCurrency ms-2">{{row.items.length}}</span>
                                                                    </span>
                                                                    <vue-feather class="rotateFeather" type="chevron-up" size="18"></vue-feather>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(item, index) in row.items" :key="index">
                                                            <td class="border-0 p-0" colspan="8">
                                                                <table class="table fixed-layout nowrap mb-0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="100" :class=" item.importance==2 ? '' : item.importance==3 ? 'redBG' : '' "><p class="neutralGrey f-13 mb-0">{{formatDate( item.date, "hh:mm a" )}}</p></td>
                                                                            <td width="200" class="medium">
                                                                                <span class="d-flex align-items-center p-0">
                                                                                    <v-lazy-image width="20" height="20" class="rounded-circle me-2" :src=" 'assets/images/country_flag/' + item.country.toLowerCase() + '.webp' " alt="Flag" :title="item.country" />{{item.country}}
                                                                                </span>
                                                                            </td>
                                                                            <td width="300 nowrap neutralGrey">{{item.event || '-'}}</td>
                                                                            <td class="red f-13">{{item.actual || '-'}}</td>
                                                                            <td class="f-13 neutralGrey">{{item.teForecast || '-'}}</td>
                                                                            <td class="f-13 neutralGrey">{{item.forecast || '-'}}</td>
                                                                            <td class="medium f-13 neutralGrey">{{item.previous || '-'}}</td>
                                                                            <td class="position-relative f-13 neutralGrey">
                                                                                <div class="d-inline-flex align-items-center justify-content-end w-100">
                                                                                    <div class="notifyAlert me-3">
                                                                                        <div
                                                                                            v-if=" modalIndexInner==index && modalIndexOuter==iOuter "
                                                                                            class="dropdown_menu_animated createAlert max-360"
                                                                                            :class=" modalIndexInner==index && modalIndexOuter==iOuter ? 'show' : '' "
                                                                                        >
                                                                                            <div class="filterHeader border-bottom mb-2 p-3 d-flex align-items-center justify-content-between">
                                                                                                <h5 class="mb-0">{{$t('calendar.text23')}}</h5>
                                                                                                <a @click.prevent=" showAlertBox( iOuter, index ) " href="javascript:void(0)" class="clearAll flex-shrink-0">
                                                                                                    <vue-feather type="x" size="20"> </vue-feather>
                                                                                                </a>
                                                                                            </div>
                                                                                            <div class="filter-gmt p-3 border-bottom">
                                                                                                <div class="form-group">
                                                                                                    <h6 class="mb-3">{{$t('calendar.text24')}}</h6>
                                                                                                    <select class="form-control form-select">
                                                                                                        <option> 02:00 {{$t('calendar.text25')}} </option>
                                                                                                        <option> 03:00 {{$t('calendar.text25')}} </option>
                                                                                                        <option> 04:00 {{$t('calendar.text25')}} </option>
                                                                                                        <option> 05:00 {{$t('calendar.text25')}} </option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div class="form-group">
                                                                                                    <h6 class="mb-3">{{$t('calendar.text26')}}</h6>
                                                                                                    <div class="d-flex align-items-center">
                                                                                                        <label class="cutomChechBox me-4">{{$t('calendar.text27')}} <input type="radio" name="radio" /> <span class="checkmark"></span> </label>
                                                                                                        <label class="cutomChechBox">{{$t('calendar.text28')}} <input type="radio" name="radio" /> <span class="checkmark"></span> </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="form-group fullWidth">
                                                                                                    <h6 class="mb-3">{{$t('calendar.text29')}}</h6>
                                                                                                    <label class="cutomChechBox">{{$t('calendar.text30')}} <input type="radio" name="radio" /> <span class="checkmark"></span> </label>
                                                                                                    <label class="cutomChechBox">{{$t('calendar.text31')}} <input type="radio" name="radio" /> <span class="checkmark"></span> </label>
                                                                                                    <label class="cutomChechBox">{{$t('calendar.text32')}} <input type="radio" name="radio" /> <span class="checkmark"></span> </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="submitBtn text-end px-3 py-4"><a href="javascript:void(0)" class="grd_btn">{{$t('calendar.text23')}}</a></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <a class="openTable circleIcon" @click="()=> toggleInner(iOuter,index,item.ticker)" :class="indexInner==index ? 'active' : ''" href="javascript:void(0)">
                                                                                        <vue-feather :type="indexInner==index ? 'chevron-up' : 'chevron-down'"></vue-feather>
                                                                                    </a>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr class="border-0" v-if=" indexInner==index && indexOuter==iOuter ">
                                                                            <td colspan="8" class="word-wrap position-relative">
                                                                                <div class="active showTable row mx-0 px-2 py-3 border-0">
                                                                                    <Loader :classname="'innerLoader'" v-if="store.tradesLoading" style="background: #fff;"></Loader>
                                                                                    <div class="col-4" v-if="store.calendarTickerData.definition ">
                                                                                        <div><p>{{store.calendarTickerData.definition || ""}}</p></div>
                                                                                    </div>
                                                                                    <div :class="store.calendarTickerData.definition ? 'col-8' : 'col-12' ">
                                                                                        <div v-show=" store.calendarTickerData.chartdata && store.calendarTickerData.chartdata.length " style="width: 100%; height: 300px;">
                                                                                            <div style="width: 100%; height: 300px;" id="tickerChart"></div>
                                                                                        </div>
                                                                                        <Nodata
                                                                                            v-show="!store.tradesLoading && !store.calendarTickerData.chartdata || (store.calendarTickerData .chartdata && !store.calendarTickerData.chartdata.length)"
                                                                                        ></Nodata>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="8" class="border-0 p-0">
                                        <Nodata v-if="!dateAdjustedEvents.length"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- <section class="blockElement space automated2">
        <div class="container">
            <div class="row align-items-start">
                <div class="col-12 mb-4">
                    <div class="d-block d-sm-flex align-items-center justify-content-between mb-3">
                        <h4 class="mb-0">Important Upcoming Events</h4>
                        <a class="button zulu_btn rounded border-button mt-2 mt-sm-0" href="javascript:void(0)">Hide Upcoming Events</a>
                    </div>
                    <div class="calendarList">
                        <div class="upcoming listCalendar flex-between d-block d-md-flex flex-wrap">
                            <div class="d-block d-md-flex align-items-center">
                                <div class="day">
                                    <p class="mb-0">1 days</p>
                                </div>
                                <div class="name d-block d-md-flex align-items-start my-2 my-md-0">
                                    <span class="midred me-2"><i class="fa fa-bar-chart"></i></span>
                                    <div class="flex-none">
                                        <h6 class="mb-0">UnitedStates - Consumer Price Index ex Food & Energy (YoY) (Oct)</h6>
                                        <p class="f-12 mb-0">Previous: 6.6%, Forecast: 6.666%</p>
                                    </div>
                                </div>
                            </div>
                            <div class="currency">
                                <span class="darkBG">USD</span>
                            </div>
                        </div>
                        <div class="upcomingLight listCalendar flex-between d-block d-md-flex flex-wrap">
                            <div class="d-block d-md-flex align-items-center">
                                <div class="day">
                                    <p class="mb-0">1 days</p>
                                </div>
                                <div class="name d-block d-md-flex align-items-start my-2 my-md-0">
                                    <span class="midred me-2"><i class="fa fa-bar-chart"></i></span>
                                    <div class="flex-none">
                                        <h6 class="mb-0">UnitedStates - Consumer Price Index ex Food & Energy (YoY) (Oct)</h6>
                                        <p class="f-12 mb-0">Previous: 6.6%, Forecast: 6.666%</p>
                                    </div>
                                </div>
                            </div>
                            <div class="currency">
                                <span class="darkBG">USD</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-3 leftBarFilter">
                    <div class="bg-white boxed mb-3">
                        <h4>Filter Economic Events</h4>
                        <div class="form-group d-flex align-items-center flex-wrap">
                            <div class="label me-2">
                                <label>From</label>
                                <input type="date" class="form-control" placeholder="From" name="">
                            </div>
                            <div class="label">
                                <label>To</label>
                                <input type="date" class="form-control" placeholder="To" name="">
                            </div>
                        </div>
                        <h6>Currency</h6>
                        <ul class="listCheck d-flex align-items-center flex-wrap">
                            <li>
                                <label class="cutomChechBox">USD
                                    <input type="checkbox" name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="cutomChechBox">EUR
                                    <input type="checkbox" name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="cutomChechBox">BGP
                                    <input type="checkbox" name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="cutomChechBox">JPY
                                    <input type="checkbox" name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="cutomChechBox">AUD
                                    <input type="checkbox" name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="cutomChechBox">CAD
                                    <input type="checkbox" name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="cutomChechBox">NZD
                                    <input type="checkbox" name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="cutomChechBox">CHF
                                    <input type="checkbox" name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="cutomChechBox">CNY
                                    <input type="checkbox" name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                        <a class="d-inline-block button fillBtn linkBtn mb-1" href="javascript:void(0)">Show Economic Events</a>
                    </div>
                    <div class="bg-white boxed mb-3">
                        <p class="f-14 mb-1">The events are displayed in the timezone</p>
                        <p class="f-14 bold flex-between">UTC+0530 <a href="javascript:void(0)" class="secondary">Change</a></p>
                        <div class="form-group">
                            <select class="form-select f-14">
                                <option selected="selected" value="0">UTC</option>
                                <option value="-2">Local Timezone</option>
                                <optgroup label="Others">
                                    <option value="-720" data-category="Others">UTC-1200</option>
                                    <option value="-660" data-category="Others">UTC-1100</option>
                                    <option value="-600" data-category="Others">UTC-1000</option>
                                    <option value="-540" data-category="Others">UTC-0900</option>
                                    <option value="-480" data-category="Others">UTC-0800</option>
                                    <option value="-420" data-category="Others">UTC-0700</option>
                                    <option value="-360" data-category="Others">UTC-0600</option>
                                    <option value="-300" data-category="Others">UTC-0500</option>
                                    <option value="-240" data-category="Others">UTC-0400</option>
                                    <option value="-210" data-category="Others">UTC-0330</option>
                                    <option value="-180" data-category="Others">UTC-0300</option>
                                    <option value="-120" data-category="Others">UTC-0200</option>
                                    <option value="-60" data-category="Others">UTC-0100</option>
                                    <option value="60" data-category="Others">UTC+0100</option>
                                    <option value="120" data-category="Others">UTC+0200</option>
                                    <option value="180" data-category="Others">UTC+0300</option>
                                    <option value="210" data-category="Others">UTC+0330</option>
                                    <option value="240" data-category="Others">UTC+0400</option>
                                    <option value="270" data-category="Others">UTC+0430</option>
                                    <option value="300" data-category="Others">UTC+0500</option>
                                    <option value="330" data-category="Others">UTC+0530</option>
                                    <option value="345" data-category="Others">UTC+0545</option>
                                    <option value="360" data-category="Others">UTC+0600</option>
                                    <option value="420" data-category="Others">UTC+0700</option>
                                    <option value="480" data-category="Others">UTC+0800</option>
                                    <option value="540" data-category="Others">UTC+0900</option>
                                    <option value="570" data-category="Others">UTC+0930</option>
                                    <option value="600" data-category="Others">UTC+1000</option>
                                    <option value="660" data-category="Others">UTC+1100</option>
                                    <option value="720" data-category="Others">UTC+1200</option>
                                    <option value="780" data-category="Others">UTC+1300</option>
                                </optgroup> 
                            </select>
                        </div>
                        <div class="reset">
                            <p class="mb-0 f-14">Reset your current event filters.<a href="javascript:void(0)" class="underline secondary">Reset</a></p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-9">
                    <div class="bg-white boxed mb-3">
                        <div class="dateByCalendar mb-4">
                            <p class="viewDate mb-2 d-flex align-items-center"><i class="me-2 fa fa-calendar" aria-hidden="true"></i> Wednesday,<span class="dueDate">09</span> Nov 2022</p>
                            <div class="calendarList">
                                <div class="bg-white listCalendar flex-between d-block d-md-flex flex-wrap">
                                    <div class="d-block d-md-flex align-items-center">
                                        <div class="day">
                                            <p class="mb-0">1 days</p>
                                        </div>
                                        <div class="name d-block d-md-flex align-items-start my-2 my-md-0">
                                            <span class="midred me-2"><i class="fa fa-bar-chart"></i></span>
                                            <div class="flex-none">
                                                <h6 class="mb-0">UnitedStates - Consumer Price Index ex Food & Energy (YoY) (Oct)</h6>
                                                <p class="f-12 mb-0">Previous: 6.6%, Forecast: 6.666%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency">
                                        <v-lazy-image width="18" class="me-2" src="/assets/images/down-arrow.svg" alt="Arrow" title="The actual value is worst than the previous one" />
                                        <span class="midredBG">CNY</span>
                                    </div>
                                </div>
                                <div class="bodyBG listCalendar flex-between d-block d-md-flex flex-wrap">
                                    <div class="d-block d-md-flex align-items-center">
                                        <div class="day">
                                            <p class="mb-0">1 days</p>
                                        </div>
                                        <div class="name d-block d-md-flex align-items-start my-2 my-md-0">
                                            <span class="midred me-2"><i class="fa fa-bar-chart"></i></span>
                                            <div class="flex-none">
                                                <h6 class="mb-0">UnitedStates - Consumer Price Index ex Food & Energy (YoY) (Oct)</h6>
                                                <p class="f-12 mb-0">Previous: 6.6%, Forecast: 6.666%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency">
                                        <span class="darkBG">USD</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dateByCalendar mb-4">
                            <p class="viewDate mb-2 d-flex align-items-center"><i class="me-2 fa fa-calendar" aria-hidden="true"></i> Wednesday,<span class="dueDate">09</span> Nov 2022</p>
                            <div class="calendarList">
                                <div class="upcoming listCalendar flex-between d-block d-md-flex flex-wrap">
                                    <div class="d-block d-md-flex align-items-center">
                                        <div class="day">
                                            <p class="mb-0">1 days</p>
                                        </div>
                                        <div class="name d-block d-md-flex align-items-start my-2 my-md-0">
                                            <span class="midred me-2"><i class="fa fa-bar-chart"></i></span>
                                            <div class="flex-none">
                                                <h6 class="mb-0">UnitedStates - Consumer Price Index ex Food & Energy (YoY) (Oct)</h6>
                                                <p class="f-12 mb-0">Previous: 6.6%, Forecast: 6.666%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency">
                                        <span class="darkBG">USD</span>
                                    </div>
                                </div>
                                <div class="upcomingLight listCalendar flex-between d-block d-md-flex flex-wrap">
                                    <div class="d-block d-md-flex align-items-center">
                                        <div class="day">
                                            <p class="mb-0">1 days</p>
                                        </div>
                                        <div class="name d-block d-md-flex align-items-start my-2 my-md-0">
                                            <span class="midred me-2"><i class="fa fa-bar-chart"></i></span>
                                            <div class="flex-none">
                                                <h6 class="mb-0">UnitedStates - Consumer Price Index ex Food & Energy (YoY) (Oct)</h6>
                                                <p class="f-12 mb-0">Previous: 6.6%, Forecast: 6.666%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency">
                                        <span class="darkBG">USD</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dateByCalendar mb-4">
                            <p class="viewDate mb-2 d-flex align-items-center"><i class="me-2 fa fa-calendar" aria-hidden="true"></i> Wednesday,<span class="dueDate">09</span> Nov 2022</p>
                            <div class="calendarList">
                                <div class="bg-white listCalendar flex-between d-block d-md-flex flex-wrap">
                                    <div class="d-block d-md-flex align-items-center">
                                        <div class="day">
                                            <p class="mb-0">1 days</p>
                                        </div>
                                        <div class="name d-block d-md-flex align-items-start my-2 my-md-0">
                                            <span class="midred me-2"><i class="fa fa-bar-chart"></i></span>
                                            <div class="flex-none">
                                                <h6 class="mb-0">UnitedStates - Consumer Price Index ex Food & Energy (YoY) (Oct)</h6>
                                                <p class="f-12 mb-0">Previous: 6.6%, Forecast: 6.666%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency">
                                        <v-lazy-image width="18" class="me-2" src="/assets/images/down-arrow.svg" alt="Arrow" title="The actual value is worst than the previous one" />
                                        <span class="midredBG">CNY</span>
                                    </div>
                                </div>
                                <div class="bodyBG listCalendar flex-between d-block d-md-flex flex-wrap">
                                    <div class="d-block d-md-flex align-items-center">
                                        <div class="day">
                                            <p class="mb-0">1 days</p>
                                        </div>
                                        <div class="name d-block d-md-flex align-items-start my-2 my-md-0">
                                            <span class="midred me-2"><i class="fa fa-bar-chart"></i></span>
                                            <div class="flex-none">
                                                <h6 class="mb-0">UnitedStates - Consumer Price Index ex Food & Energy (YoY) (Oct)</h6>
                                                <p class="f-12 mb-0">Previous: 6.6%, Forecast: 6.666%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency">
                                        <span class="darkBG">USD</span>
                                    </div>
                                </div>
                                <div class="bg-white listCalendar flex-between d-block d-md-flex flex-wrap">
                                    <div class="d-block d-md-flex align-items-center">
                                        <div class="day">
                                            <p class="mb-0">1 days</p>
                                        </div>
                                        <div class="name d-block d-md-flex align-items-start my-2 my-md-0">
                                            <span class="midred me-2"><i class="fa fa-bar-chart"></i></span>
                                            <div class="flex-none">
                                                <h6 class="mb-0">UnitedStates - Consumer Price Index ex Food & Energy (YoY) (Oct)</h6>
                                                <p class="f-12 mb-0">Previous: 6.6%, Forecast: 6.666%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency">
                                        <v-lazy-image width="18" class="me-2" src="/assets/images/down-arrow.svg" alt="Arrow" title="The actual value is worst than the previous one" />
                                        <span class="midredBG">CNY</span>
                                    </div>
                                </div>
                                <div class="bodyBG listCalendar flex-between d-block d-md-flex flex-wrap">
                                    <div class="d-block d-md-flex align-items-center">
                                        <div class="day">
                                            <p class="mb-0">1 days</p>
                                        </div>
                                        <div class="name d-block d-md-flex align-items-start my-2 my-md-0">
                                            <span class="midred me-2"><i class="fa fa-bar-chart"></i></span>
                                            <div class="flex-none">
                                                <h6 class="mb-0">UnitedStates - Consumer Price Index ex Food & Energy (YoY) (Oct)</h6>
                                                <p class="f-12 mb-0">Previous: 6.6%, Forecast: 6.666%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency">
                                        <span class="darkBG">USD</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
</template>
<script>
import { myStore } from "@/store/pinia-store";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import moment from "moment";
import $ from "jquery";

export default {
setup() {
    const store = myStore();
    return { store };
},
data() {
    return {
        countryFilterOptions: [
            "All",
            "Major",
            "Asia",
            "America",
            "Europe",
            "Australia",
        ],
        currentCountryFilter: "Major",
        selectedCountries: [],
        addFilter: false,
        addImpactFilter: false,
        addTimeZone: false,
        selectedTimeZone: 0,
        openTable: false,
        addAlert: false,
        dropdownMenu: false,
        dropdownMenu2: false,
        showCalendar: false,
        submitVote: false,
        modalIndexInner: -1,
        modalIndexOuter: -1,
        indexInner: -1,
        sliderValue: 0,
        slider: { value: 0, low: 0, high: 0 },
        step: 0,
        caldata: {},
        filterOpen: {
            timezone: false,
            impact: false,
            country: false,
        },
        filterJson: {
            recent: this.$t('calendar.text9'),
            today: this.$t('calendar.text33'),
            tomorrow: this.$t('calendar.text10'),
            week: this.$t('calendar.text11'),
            nextWeek: this.$t('calendar.text12'),
            nextMonth: this.$t('calendar.text13'),
            yesterday: this.$t('calendar.text14'),
            prevWeek: this.$t('calendar.text15'),
            prevMonth: this.$t('calendar.text16'),
            custom: this.$t('calendar.text17'),
        },
        dateChangeActive: "recent",
        indexOuter: 0,
        customCalendarDate: [],
        filters: {
            impact: 0,
            countries: [],
            dates: {
                from: moment().subtract(1, "day").format("YYYY-MM-DD"),
                to: moment().add(10, 'day').format("YYYY-MM-DD"),
            },
        },
        hideDateWise : []
    };
},
watch: {
    "filters.impact"() {
        this.getCalendarList();
    },
},
computed: {
    allCountries() {
        let arr = [];
        for (let key in this.static_vars.economicCalendarCountries) {
            this.static_vars.economicCalendarCountries[key].map((i) => {
                arr.push(i);
            });
        }
        return [...new Set(arr)].sort((a, b) => a.localeCompare(b));
    },
    dateAdjustedEvents() {
        if (Object.keys(this.store.calendarData).length) {
            let temp = [],
                allEvents = [];
            for (let key in this.store.calendarData) {
                this.store.calendarData[key].items.map((i) => {
                    let obj = Object.assign({}, i);
                    obj.date = this.COMMON.formatCalendarDate(
                        obj.date,
                        "YYYY-MM-DD HH:mm",
                        this.selectedTimeZone
                    );
                    //console.log(obj);
                    temp.push(obj);
                });
            }
            temp.map((i) => {
                const ind = allEvents.findIndex(
                    (j) =>
                        moment(j.date).format("YYYY-MM-DD") ==
                        moment(i.date).format("YYYY-MM-DD")
                );
                if (ind > -1) {
                    if (allEvents[ind].items) {
                        allEvents[ind].items.push(i);
                    } else {
                        allEvents[ind].items = [i];
                    }
                } else {
                    allEvents.push({
                        date: moment(i.date).format("YYYY-MM-DD"),
                        items: [i],
                    });
                }
            });
            return allEvents;
        } else {
            return [];
        }
    },
},
mounted() {
    const _that = this;
    $(document).on("click", function (event) {
        if (!$(event.target).closest(".filter-dropdown").length) {
            _that.dropdownMenu = false;
            //_that.showCalendar = false;
        }
    });
    $(document).on("click", function (event) {
        if (!$(event.target).closest(".custom-date").length) {
           // _that.dropdownMenu = false;
            _that.showCalendar = false;
        }
        if (!$(event.target).closest(".addFilter").length) {
            _that.filterOpen.country = false;
            _that.filterOpen.impact = false;
            _that.filterOpen.timezone = false;
        }
    });
    this.selectedCountries = this.static_vars.economicCalendarCountries[this.currentCountryFilter.toLowerCase()];
    this.getCalendarList();
    // const settings = {
    //     fill: "#1abc9c",
    //     background: "#d7dcdf",
    // };
},
methods: { 
    hideDateRow(row){
        if(Object.keys(row) && row.date){
            if(!this.hideDateWise.includes(row.date)){
                this.hideDateWise.push(row.date)
            }else{
                let index  = this.hideDateWise.indexOf(row.date,0);
                this.hideDateWise.splice(index,1)
            }
        }
    },
    formatCustomDate(date) {
        return moment(date).format("L");
    },
    toggleCalendar() {
        this.showCalendar = !this.showCalendar;
    },
    handleDate() {
        if ((this.customCalendarDate[0], this.customCalendarDate[1])) {
            this.showCalendar = false;
            this.filters.dates.from = moment(this.customCalendarDate[0]).format(
                "YYYY-MM-DD"
            );
            this.filters.dates.to = moment(this.customCalendarDate[1]).format(
                "YYYY-MM-DD"
            );
            this.getCalendarList();
        }
    },
    toggleFilter(key) {
        for (let k in this.filterOpen) {
            if (k == key) {
                this.filterOpen = Object.assign(this.filterOpen, {
                    [k]: !this.filterOpen[key],
                });
            } else {
                this.filterOpen = Object.assign(this.filterOpen, { [k]: false });
            }
        }
    },

    clearCountries() {
        this.selectedCountries = [];
    },
    getCalendarList() {
        this.toggleFilter("all");
        const d = new Date();
        let formData = {
            filter: {
                country: this.selectedCountries,
                ...this.filters.dates,
                offset: d.getTimezoneOffset()
            },
        };
        if (this.filters.impact > 0) {
            formData.filter.priority = parseFloat(this.filters.impact);
        }
        this.store.getCalendar(formData, true).then(() => { 
            this.hideDateWise = [];
        }).catch((err) => console.log(err.message));
    },
    toggleSelection(country) {
        let ind = this.selectedCountries.findIndex((i) => i === country);
        if (ind > -1) {
            this.selectedCountries.splice(ind, 1);
        } else {
            this.selectedCountries.push(country);
        }
    },
    changeDate(type) {
        this.dateChangeActive = type;
        if (type == "recent") {
            this.filters.dates.from = moment().subtract(1, "day").format("YYYY-MM-DD");
            this.filters.dates.to = moment().add(10, 'day').format("YYYY-MM-DD");
        } else if (type == "yesterday") {
            this.filters.dates.from = moment()
                .subtract(1, "day")
                .format("YYYY-MM-DD");
            this.filters.dates.to = moment()
                .subtract(1, "day")
                .format("YYYY-MM-DD");
        } else if (type == "today") {
            this.filters.dates.from = moment().format("YYYY-MM-DD");
            this.filters.dates.to = moment().format("YYYY-MM-DD");
        } else if (type == "tomorrow") {
            this.filters.dates.from = moment().add(1, "day").format("YYYY-MM-DD");
            this.filters.dates.to = moment().add(1, "day").format("YYYY-MM-DD");
        } else if (type == "week") {
            this.filters.dates.from = moment().startOf("week").format("YYYY-MM-DD");
            this.filters.dates.to = moment().endOf("week").format("YYYY-MM-DD");
        } else if (type == "nextWeek") {
            this.filters.dates.from = moment()
                .add(1, "weeks")
                .startOf("week")
                .format("YYYY-MM-DD");
            this.filters.dates.to = moment()
                .add(1, "weeks")
                .endOf("week")
                .format("YYYY-MM-DD");
        } else if (type == "nextMonth") {
            this.filters.dates.from = moment()
                .add(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
            this.filters.dates.to = moment()
                .add(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD");
        } else if (type == "prevWeek") {
            this.filters.dates.from = moment()
                .subtract(1, "weeks")
                .startOf("isoWeek")
                .format("YYYY-MM-DD");
            this.filters.dates.to = moment()
                .subtract(1, "weeks")
                .endOf("isoWeek")
                .format("YYYY-MM-DD");
        } else if (type == "prevMonth") {
            this.filters.dates.from = moment()
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
            this.filters.dates.to = moment()
                .subtract(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD");
        }
        this.getCalendarList();
        this.dropdownMenu = false;
    },
    selectCountriesFromTab(tab) {
        this.currentCountryFilter = tab;
        if (tab == "All") {
            let arr = [];
            for (let key in this.static_vars.economicCalendarCountries) {
                this.static_vars.economicCalendarCountries[key].map((i) => {
                    arr.push(i);
                });
            }
            this.selectedCountries = [...new Set(arr)];
        } else {
            this.selectedCountries = [
                ...new Set(
                    this.static_vars.economicCalendarCountries[tab.toLowerCase()]
                ),
            ];
        }
    },
    inSelectedCountries(country) {
        try {
            if (this.selectedCountries.includes(country)) return true;
            return false;
        } catch (error) {
            console.log(error);
        }
    },
    getTimezoneSelected(val) {
        let obj = this.static_vars.timezonesCalendar.find((i) => i.value === val);
        return obj ? obj.label : "System Default";
    },
    showAlertBox(indexOuter, index) {
        if (this.modalIndexInner == index) {
            this.modalIndexInner = -1;
            this.modalIndexOuter = -1;
        } else {
            this.modalIndexInner = index;
            this.modalIndexOuter = indexOuter;
        }
    },
    getFormattedPrice(price) {
        if (typeof price != "number") price = parseFloat(price);
        if (isNaN(price)) return 0;
        price = price.toFixed(2);
        // return price.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')
        let stringPrice =
            parseInt(price).toLocaleString() +
            (price.toString().indexOf(".") != -1 ? "." + price.split(".")[1] : "");
        // return stringPrice.replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1");
        return stringPrice;
    },
    setSliderValues(operation) {
        let item =
            this.store.calendarData[this.indexOuter].items[this.indexInner];
        let initialValue = item.teForecast
            ? parseFloat(`${item.teForecast}`.match(/[+-]?\d+(\.\d+)?/g)[0])
            : item.previous
                ? parseFloat(`${item.previous}`.match(/[+-]?\d+(\.\d+)?/g)[0])
                : 0;
        if (operation) {
            if (operation == "add") {
                this.slider.high = parseFloat(
                    this.slider.high + (Math.abs(initialValue) / 100) * 30
                );
            } else if (operation == "sub") {
                this.slider.low = parseFloat(
                    this.slider.low - (Math.abs(initialValue) / 100) * 30
                );
            }
        } else {
            this.slider = {
                value: initialValue,
                high: parseFloat(initialValue + (Math.abs(initialValue) / 100) * 30),
                low: parseFloat(initialValue - (Math.abs(initialValue) / 100) * 30),
            };
        }
        this.step =
            (Math.abs(this.slider.high) + Math.abs(this.slider.low)) / 100;
    },
    toggleInner(indexOuter, index, symbol) {
        if (this.indexInner == index) {
            this.indexInner = -1;
            this.indexOuter = indexOuter;
        } else {
            this.indexOuter = indexOuter;
            this.store.calendarTickerData = {};
            this.indexInner = index;
            this.setSliderValues();
            this.store.getCalendarTickerHistory({ Symbol: symbol }, true)
                .then(() => {
                    setTimeout(() => {
                        am5.ready(() => {
                            this.drawBarChart();
                        });
                    }, 300);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    formatDate(date, format = 'Do MMM YYYY') {
        if (!date) return 'N/A'
        if (format && format == 'now') return moment(date).fromNow()
        return moment(date).format(format)
    },
    disposeRoot(id) {
        let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
        d?.dispose();
    },
    drawBarChart() {
        if (
            this.store.calendarTickerData.chartdata &&
            this.store.calendarTickerData.chartdata.length
        ) {
            this.disposeRoot("tickerChart");
            var root = am5.Root.new("tickerChart");
            root.setThemes([am5themes_Animated.new(root)]);
            var chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    // wheelX: "panX",
                    // wheelY: "zoomX",
                })
            );
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            var xRenderer = am5xy.AxisRendererX.new(root, {});
            xRenderer.labels.template.setAll({
                // rotation: -45,
                centerY: am5.p50,
                // centerX: am5.p100,
            });
            var xAxis = chart.xAxes.push(
                am5xy.GaplessDateAxis.new(root, {
                    baseInterval: { timeUnit: "month", count: 1 },
                    gridIntervals: [{ timeUnit: "month", count: 4 }],
                    renderer: xRenderer,
                    // tooltip: am5.Tooltip.new(root, {}),
                })
            );
            var yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {}),
                })
            );
            let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color(0x666666),
                strokeWidth: 1
            });

            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);
            var series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "Ticker",
                    xAxis: xAxis,
                    connect: true,
                    yAxis: yAxis,
                    valueXField: "date",
                    valueYField: "value",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueX.formatDate('MMM yyyy')} : {valueY}",
                    }),
                })
            );
            // series.columns.template.setAll({  width: am5.percent(50),});
            series.columns.template.adapters.add("stroke", (fill, target) => {
                if (target._dataItem.dataContext.value > 0) {
                    return "#ff7200";
                } else {
                    return "#4572a7";
                }
            });
            series.columns.template.adapters.add("fill", (fill, target) => {
                if (target._dataItem.dataContext.value > 0) {
                    return "#ff7200";
                } else {
                    return "#4572a7";
                }
            });
            var data = this.store.calendarTickerData.chartdata.slice(0,10).reverse();
            xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#666666")),
                fontSize: 12,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });
            xAxis.data.setAll(data);
            series.data.setAll(data);
            chart.zoomOutButton.set("forceHidden", true);
            series.appear(300);
            chart.appear(300, 100);
        }
    },
},
};
</script>